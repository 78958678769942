import HttpService from "../HttpService";
import DisciplineResult from "@/models/sportevent/DisciplineResult";
import BaseService from "../BaseService";

class DisciplineResultService extends BaseService<DisciplineResult> {
  public url: string;
  constructor(url = "sportevent/disciplineresult/") {
    super(url, "");
    this.url = url;
  }

  async getAllByStartGroupAndDisciplineCategory(
    startgroupId: string,
    disciplineCategoryId: string
  ) {
    let response = await HttpService.get<DisciplineResult[]>(
      `${this.url}bystartgroupanddisciplinecategory/${startgroupId}/${disciplineCategoryId}/`
    );
    response = response?.map((i) => new DisciplineResult(i));
    return response;
  }

  async saveList(
    disciplineResults: DisciplineResult[]
  ): Promise<DisciplineResult[]> {
    let response = await HttpService.put<DisciplineResult[]>(
      `${this.url}list_update/`,
      disciplineResults
    );
    if (response !== undefined) {
      response = response.map((i) => new DisciplineResult(i));
      return response;
    }
    return [];
  }
}
export default new DisciplineResultService();
