import Starter from "./Starter";
import Discipline from "../sporteventCalculation/Discipline";
import SporteventCompetition from "./SporteventCompetition";
import DisciplineResultAValue from "./DisciplineResultAValue";
import StartGroup from "./StartGroup";
import DisciplineResultBValue from "./DisciplineResultBValue";

export default class DisciplineResult {
  id?: string;
  starter: Starter = new Starter();
  startGroup: StartGroup = new StartGroup();
  discipline: Discipline = new Discipline();
  sporteventCompetition: SporteventCompetition = new SporteventCompetition();
  calculatedPoints = 0;
  calculatedBPoints = 0;
  agePoints = 0;
  aValues: DisciplineResultAValue[] = [];
  usedAValue = 0;
  usedPenaltyPoints = 0;
  forTeamEvaluation = false;
  valuesJsonObject = "";

  constructor(disciplineResult?: DisciplineResult, bValueCount = 2) {
    if (disciplineResult === undefined || disciplineResult === null) {
      return;
    }
    this.id = disciplineResult.id;
    this.starter = disciplineResult.starter;
    this.startGroup = disciplineResult.startGroup;
    this.discipline = disciplineResult.discipline;
    this.sporteventCompetition = disciplineResult.sporteventCompetition;
    this.calculatedPoints = disciplineResult.calculatedPoints;
    this.agePoints = disciplineResult.agePoints;
    //this.aValues = disciplineResult.aValues;
    this.usedPenaltyPoints = disciplineResult.usedPenaltyPoints;
    this.forTeamEvaluation = disciplineResult.forTeamEvaluation;
    this.valuesJsonObject = disciplineResult.valuesJsonObject;

    if (this.valuesJsonObject !== undefined && this.valuesJsonObject !== "") {
      this.aValues = JSON.parse(this.valuesJsonObject).map(
        (v: DisciplineResultAValue) => new DisciplineResultAValue(v)
      );
    }

    if (this.aValues.length == 0) {
      for (let i = 0; i < this.discipline.repetition; i++) {
        const aValue = new DisciplineResultAValue();
        aValue.value = 0;
        for (let v = 0; v < bValueCount; v++) {
          aValue.bValues.push(new DisciplineResultBValue());
        }
        this.aValues.push(aValue);
      }
    } else {
      for (let i = 0; i < this.aValues.length; i++) {
        for (let v = this.aValues[i].bValues.length; v < bValueCount; v++) {
          this.aValues[i].bValues.push(new DisciplineResultBValue());
        }
      }
    }
  }
}
