import DisciplineResultBValue from "./DisciplineResultBValue";

export default class DisciplineResultAValue {
  id?: string;
  value = 0;
  bValues: DisciplineResultBValue[] = [];
  penaltyPoints = 0;
  addInputValue = 0;

  constructor(disciplineResultAValue?: DisciplineResultAValue) {
    if (
      disciplineResultAValue === undefined ||
      disciplineResultAValue === null
    ) {
      return;
    }
    this.id = disciplineResultAValue.id;

    if (
      disciplineResultAValue.value !== undefined &&
      disciplineResultAValue.value !== null &&
      disciplineResultAValue.value > 0
    ) {
      this.value = disciplineResultAValue.value;
    }

    this.penaltyPoints = disciplineResultAValue.penaltyPoints;
    this.bValues = disciplineResultAValue.bValues;
    this.addInputValue = 0;
  }
}
