export default class DisciplineResultBValue {
  id?: string;
  value = 0;

  constructor(disciplineResultBValue?: DisciplineResultBValue) {
    if (
      disciplineResultBValue === undefined ||
      disciplineResultBValue === null
    ) {
      return;
    }
    this.id = disciplineResultBValue.id;
    this.value = disciplineResultBValue.value;
  }
}
