
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";

import DisciplineResultService from "../../services/sportevent/DisciplineResultService";
import DisciplineResult from "../../models/sportevent/DisciplineResult";
import StartGroupService from "../../services/sportevent/StartGroupService";
import StartGroup from "../../models/sportevent/StartGroup";
import Discipline from "../../models/sporteventCalculation/Discipline";
import Jury from "../../models/sportevent/Jury";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import DisciplineResultAValue from "../../models/sportevent/DisciplineResultAValue";
import DisciplineResultBValue from "../../models/sportevent/DisciplineResultBValue";

@Component({})
export default class ScoringComponent extends Vue {
  public startgroup: StartGroup = new StartGroup();
  public currentDiscipline: Discipline = new Discipline();
  public jury: Jury[] = [];
  // Loading
  public loading = true;
  public loadingScoring = false;
  // Table
  public items: DisciplineResult[] = [];

  public fields = [
    {
      key: "position",
      sortable: false,
      label: "",
      class: "position",
    },
    {
      key: "starter",
      sortable: false,
      class: "starter",
    },
    {
      key: "discipline",
      sortable: false,
      label: i18n.tc("sporteventCalculation.discipline"),
      class: "discipline text-center",
    },
    {
      key: "points",
      sortable: false,
      class: "points-column text-center",
    },
    {
      key: "unit",
      sortable: false,
      class: "unit text-center",
    },
    {
      key: "calculatedPoints",
      sortable: false,
      label: i18n.tc("sportevent.calculatedPoints"),
      class: "text-center",
    },
  ];
  public filter = "";
  public perPage = 20;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 0;
  public currentPage = 1;

  mounted(): void {
    this.getAll();
  }

  async getAll(): Promise<void> {
    this.loading = true;
    this.startgroup = new StartGroup(
      await StartGroupService.get(this.$route.params.startgroupId)
    );

    const items =
      await DisciplineResultService.getAllByStartGroupAndDisciplineCategory(
        this.$route.params.startgroupId,
        this.$route.params.disciplineCategoryId
      );

    if (items !== undefined) this.items = items;

    //console.log("ITEMS:", this.items);
    this.addData();
  }

  addData(): void {
    this.items.forEach((result: DisciplineResult): void => {
      // Aufgrund der Performance sind keine Starter-Informationen außer der ID vorhanden, hole diese aus der Startgruppe.
      const starter = this.startgroup.starters.find(
        (s) => s.id === result.starter.id
      );
      if (starter !== undefined) {
        result.starter = starter;
      }
      this.startgroup.sporteventCompetitions.forEach(
        (sc: SporteventCompetition) => {
          const discipline = sc.disciplines.find(
            (d) => d.id === result.discipline.id
          );
          if (discipline !== undefined && discipline !== null) {
            result.discipline = discipline;
            if (
              this.currentDiscipline === null ||
              this.currentDiscipline === undefined ||
              this.currentDiscipline.name === ""
            ) {
              this.currentDiscipline = discipline;
            }
          }
          const repetitionDiff =
            this.currentDiscipline.repetition - result.aValues.length;
          if (repetitionDiff > 0) {
            for (let i = 0; i < repetitionDiff; i++) {
              let newAValue = new DisciplineResultAValue();
              if (this.currentDiscipline.hasBPoints) {
                for (let x = 0; x < 2; x++) {
                  newAValue.bValues.push(new DisciplineResultBValue());
                }
              }
              result.aValues.push(newAValue);
            }
          }
        }
      );
    });
    this.totalRows = this.items.length;
    console.log("DisciplineResults:", this.items);

    let numberOfJudges = 2;
    if (this.jury != undefined) {
      const judge = this.jury.find(
        (judge) =>
          judge.disciplineCategory.id == this.$route.params.disciplineCategoryId
      );
      if (judge != undefined) {
        numberOfJudges = judge.numberOfJudges;
      }
    }

    this.loading = false;
  }

  handleBack(): void {
    this.$router.back();
  }

  enablePoints(id: string): void {
    const element = document.getElementById(id) as HTMLInputElement;
    element.disabled = false;
  }

  async save(handleBack = true): Promise<void> {
    this.loadingScoring = true;
    console.log(this.items);
    this.items.forEach((disciplineResult: DisciplineResult) => {
      console.log(JSON.stringify(disciplineResult.aValues));
      disciplineResult.aValues.forEach((a: DisciplineResultAValue) => {
        if (a.addInputValue > 0) {
          a.value = Number(a.value) + Number(a.addInputValue) * 60;
        }
      });
      disciplineResult.valuesJsonObject = JSON.stringify(
        disciplineResult.aValues
      );
    });
    const disciplineResultsUpdated = await DisciplineResultService.saveList(
      this.items
    );

    disciplineResultsUpdated.forEach(
      (disciplineResultUpdated: DisciplineResult) => {
        const index = this.items.findIndex(
          (i) => i.id == disciplineResultUpdated.id
        );
        if (index > -1) {
          this.items[index].calculatedPoints =
            disciplineResultUpdated.calculatedPoints;
          this.items[index].calculatedBPoints =
            disciplineResultUpdated.calculatedBPoints;
          this.items[index].agePoints = disciplineResultUpdated.agePoints;
          this.items[index].aValues = disciplineResultUpdated.aValues;
          console.log(this.items[index].aValues);
          this.items[index].usedAValue = disciplineResultUpdated.usedAValue;
          this.items[index].usedPenaltyPoints =
            disciplineResultUpdated.usedPenaltyPoints;
          this.items[index].forTeamEvaluation =
            disciplineResultUpdated.forTeamEvaluation;
          this.items[index].valuesJsonObject =
            disciplineResultUpdated.valuesJsonObject;
        }
      }
    );

    if (handleBack) {
      this.handleBack();
    }
    if (this.items !== undefined) {
      ToastService.Success(
        i18n.tc("labels.saved"),
        this.startgroup.StartGroupName
      );
    }
    this.loadingScoring = false;
  }
}
