var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.loading)?_c('div',[_c('div',{staticClass:"header"},[_c('h2',[_vm._v(" "+_vm._s(_vm.startgroup.StartGroupName)+" - "+_vm._s(_vm.currentDiscipline.disciplineCategory.name)+" ")])]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save(false)}}},[_c('div',{class:'inner'},[_c('b-table',{staticClass:"scoreing",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"head(starter)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc("sportevent.starter"))+" ")]},proxy:true},{key:"head(unit)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("sporteventCalculation.unit"))+" ")]},proxy:true},{key:"footer()",fn:function(){return [_vm._v(" . ")]},proxy:true},{key:"head(points)",fn:function(){return [_c('div',{staticClass:"values"},[(_vm.currentDiscipline.hasPenaltyPoints)?_c('div',{staticClass:"penalty-points"},[_vm._v(" "+_vm._s(_vm.$t("sportevent.penaltyPoints"))+" ")]):_vm._e(),(
                  _vm.currentDiscipline.unit.id ==
                  'e2fd7e0f-ac2c-43ca-80e5-cf3257f04cdf'
                )?_c('div',{staticClass:"addInputValueLabel"},[_vm._v(" Minuten ")]):_vm._e(),(
                  _vm.currentDiscipline.unit.id ==
                  'e2fd7e0f-ac2c-43ca-80e5-cf3257f04cdf'
                )?_c('div',{staticClass:"addInputValueLabel"}):_vm._e(),_c('div',{staticClass:"a-value"},[_vm._v("Leistung")]),(_vm.currentDiscipline.hasBPoints)?_c('div',{staticClass:"b-value"},[_vm._v(" "+_vm._s(_vm.$t("sportevent.bValue"))+" ")]):_vm._e(),(_vm.currentDiscipline.hasBPoints)?_c('div',{staticClass:"b-value"},[_vm._v(" "+_vm._s(_vm.$t("sportevent.bValue"))+" ")]):_vm._e()])]},proxy:true},{key:"cell(position)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.starter.positionInStartgroup)+" ")]}},{key:"cell(starter)",fn:function(row){return [(row.item.starter && row.item.starter.person)?_c('span',{staticClass:"starter-name"},[_vm._v(" "+_vm._s(row.item.starter.person.firstname)+" "+_vm._s(row.item.starter.person.lastname)+" ")]):_vm._e(),_c('br'),(row.item.starter && row.item.starter.startsForSociety)?_c('small',[_vm._v(" "+_vm._s(row.item.starter.startsForSociety.shortname)),_c('br'),_vm._v(" "+_vm._s(row.item.starter.sporteventCompetition.competition.name)+" ")]):_vm._e()]}},{key:"cell(discipline)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.discipline.name)+" ")]}},{key:"cell(unit)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.item.discipline.unit.shortname)+" ")])]}},{key:"cell(points)",fn:function(row){return [(!_vm.loadingScoring)?_c('div',{staticClass:"values"},_vm._l((row.item.aValues),function(aValue,index){return _c('div',{key:aValue.id,class:!_vm.currentDiscipline.hasBPoints &&
                  _vm.currentDiscipline.repetition > 1
                    ? `repetition repetition-${row.item.discipline.repetition}`
                    : 'repetition-row'},[(_vm.currentDiscipline.hasPenaltyPoints)?_c('div',{staticClass:"penalty-points",on:{"click":function($event){return _vm.enablePoints(
                      `aValue.penaltyPoints-${index}-${row.item.starter.id}`
                    )}}},[_c('b-form-input',{staticClass:"penalty-points-input",attrs:{"id":`aValue.penaltyPoints-${index}-${row.item.starter.id}`,"state":null,"step":"0.001","min":"0","max":row.item.discipline.maximumAValue,"type":"number"},model:{value:(aValue.penaltyPoints),callback:function ($$v) {_vm.$set(aValue, "penaltyPoints", $$v)},expression:"aValue.penaltyPoints"}})],1):_vm._e(),(
                    row.item.discipline.unit.id ==
                    'e2fd7e0f-ac2c-43ca-80e5-cf3257f04cdf'
                  )?_c('div',{staticClass:"addInputValueLabel"},[_vm._v(" min. ")]):_vm._e(),(
                    row.item.discipline.unit.id ==
                    'e2fd7e0f-ac2c-43ca-80e5-cf3257f04cdf'
                  )?_c('div',{staticClass:"a-value"},[_c('b-form-input',{staticClass:"a-value-input",attrs:{"id":`aValue.value-min-${index}-${row.item.starter.id}`,"state":null,"step":"1","min":"0","disabled":index > 0,"max":row.item.discipline.maximumAValue,"type":"number"},model:{value:(aValue.addInputValue),callback:function ($$v) {_vm.$set(aValue, "addInputValue", $$v)},expression:"aValue.addInputValue"}})],1):_vm._e(),_c('div',{staticClass:"a-value",on:{"click":function($event){return _vm.enablePoints(
                      `aValue.value-${index}-${row.item.starter.id}`
                    )}}},[_c('b-form-input',{staticClass:"a-value-input",class:[
                      row.item.discipline.hasBPoints ? '' : 'noBPoints',
                    ],attrs:{"id":`aValue.value-${index}-${row.item.starter.id}`,"state":null,"step":row.item.discipline.hasBPoints ? '0.25' : '0.001',"min":"0","disabled":index > 0,"max":row.item.discipline.maximumAValue,"type":"number"},model:{value:(aValue.value),callback:function ($$v) {_vm.$set(aValue, "value", $$v)},expression:"aValue.value"}})],1),_vm._l((aValue.bValues),function(bValues,bindex){return (row.item.discipline.hasBPoints)?_c('div',{key:bValues.id,staticClass:"b-value",on:{"click":function($event){return _vm.enablePoints(
                      `bValues.value-${index}-${bindex}-${row.item.starter.id}`
                    )}}},[_c('b-form-input',{staticClass:"b-value-input",attrs:{"id":`bValues.value-${index}-${bindex}-${row.item.starter.id}`,"state":null,"step":"0.001","min":"0","disabled":index > 0,"max":row.item.discipline.maximumBValue,"type":"number"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},model:{value:(bValues.value),callback:function ($$v) {_vm.$set(bValues, "value", $$v)},expression:"bValues.value"}})],1):_vm._e()})],2)}),0):_vm._e(),(_vm.loadingScoring)?_c('vue-loading'):_vm._e()]}},{key:"cell(calculatedPoints)",fn:function(row){return [(!_vm.loadingScoring)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.item.calculatedPoints.toLocaleString())+" ")]):_vm._e(),(_vm.loadingScoring)?_c('vue-loading'):_vm._e()]}}],null,false,819442235)}),(_vm.loading)?_c('vue-loading'):_vm._e()],1),_c('div',{staticClass:"footer"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"4"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleBack()}}},[_vm._v(_vm._s(_vm.$t("labels.back"))+" ")])],1),_c('b-col',{staticClass:"pb-2",attrs:{"lg":"4"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save(false)}}},[_vm._v(" "+_vm._s(_vm.$t("sportevent.calculate"))+" ")])],1),_c('b-col',{staticClass:"pb-2",attrs:{"lg":"4"}},[_c('b-button',{attrs:{"block":""},on:{"click":function($event){$event.preventDefault();return _vm.save(true)}}},[_vm._v(_vm._s(_vm.$t("labels.save")))])],1)],1)],1)])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }